import React, { useState, useEffect } from 'react';
import { config } from '../config';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { name: 'Home', href: '#hero' },
    { name: 'Technology', href: '#technology' },
    { name: 'Team', href: '#team' },
    { name: 'Advisors', href: '#advisors' },
  ];

  const contactButtonStyle = {
    backgroundColor: config.colors.primary,
    color: config.colors.background,
    padding: '0.5rem 1rem',
    borderRadius: '5px',
    textDecoration: 'none',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  };

  return (
    <header style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      padding: '1rem',
      zIndex: 1000,
    }}>
      <nav style={{
        maxWidth: config.sizes.maxWidth,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <div style={{ fontFamily: config.fonts.heading, fontSize: '1.5rem', color: config.colors.primary }}>Roboforge</div>
        {isMobile ? (
          <div>
            <button onClick={toggleMenu} style={{ background: 'none', border: 'none', color: config.colors.primary, fontSize: '1.5rem' }}>
              ☰
            </button>
            {isMenuOpen && (
              <ul style={{
                listStyle: 'none',
                padding: 0,
                margin: 0,
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                transition: 'all 0.3s ease-in-out',
              }}>
                {menuItems.map((item) => (
                  <li key={item.name} style={{ padding: '0.5rem 1rem' }}>
                    <a href={item.href} style={{ color: config.colors.primary, textDecoration: 'none' }} onClick={toggleMenu}>
                      {item.name}
                    </a>
                  </li>
                ))}
                <li style={{ padding: '0.5rem 1rem', marginTop: '0.5rem', borderTop: `1px solid ${config.colors.primary}` }}>
                  <a href="mailto:info@roboforge.io" style={contactButtonStyle}>
                    Contact Us
                  </a>
                </li>
              </ul>
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ul style={{ listStyle: 'none', display: 'flex', gap: '1.5rem', alignItems: 'center', marginRight: '2rem' }}>
              {menuItems.map((item) => (
                <li key={item.name}>
                  <a href={item.href} style={{ color: config.colors.primary, textDecoration: 'none' }}>
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
            <a href="mailto:info@roboforge.io" style={contactButtonStyle}>
              Contact Us
            </a>
          </div>
        )}
      </nav>
    </header>
  );
}