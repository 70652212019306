export const config = {
    colors: {
      background: '#000005',
      primary: '#FFFFFF',
      secondary: '#222222',
    },
    fonts: {
      main: '"Roboto", sans-serif',
      heading: '"Poppins", sans-serif',
    },
    spacing: {
      section: '4rem',
    },
    sizes: {
      maxWidth: '1200px',
    },
  };