import React from 'react';
import { config } from '../config';




const advisors = [
  // { name: 'Jennifer Gill Roberts', image: 'JR_headshot.jpeg', description: 'VC Advisor', linkedin: 'https://www.linkedin.com/in/jennifergillroberts/' },
  { name: 'Steve Taub', image: 'ST_headshot.jpeg', description: 'VC & Commercial Market Advisor', linkedin: 'https://www.linkedin.com/in/stevetaub/' },
  { name: 'Shane Skopak', image: 'SS_headshot.jpeg', description: 'DoD Advisor', linkedin: 'https://www.linkedin.com/in/shane-skopak-b39269b6/' },
];

export default function Advisors() {
  return (
    <section id="advisors" style={{
      maxWidth: config.sizes.maxWidth,
      margin: '0 auto',
      padding: config.spacing.section,
    }}>
      <h2 style={{ fontFamily: config.fonts.heading, fontSize: '2.5rem', marginBottom: '2rem', textAlign: 'center' }}>Our Advisors</h2>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', flexWrap: 'wrap' }}>
        {advisors.map((advisor, index) => (
          <div key={index} style={{ textAlign: 'center', maxWidth: '300px' }}>
            <img src={advisor.image} alt={advisor.name} style={{ width: '200px', height: '200px', borderRadius: '50%', marginBottom: '1rem' }} />
            <h3 style={{ fontFamily: config.fonts.heading, fontSize: '1.5rem', marginBottom: '0.5rem' }}>{advisor.name}</h3>
            <p style={{ marginBottom: '0.5rem' }}>{advisor.description}</p>
            <a href={advisor.linkedin} target="_blank" rel="noopener noreferrer" style={{ color: config.colors.primary, fontSize: '1.5rem' }}>
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}