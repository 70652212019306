import React from 'react';
import { config } from '../config';

export default function Technology() {
  return (
    <section id="technology" style={{
      position: 'relative',
      minHeight: '100vh',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'stretch',
    }}>
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      >
        <source src="technologyvideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={{
        position: 'relative',
        zIndex: 1,
        width: '30%',
        minWidth: '400px',
        maxWidth: '500px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: config.colors.primary,
        padding: '2rem 2rem 2rem 0',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <div style={{ paddingLeft: '2rem' }}>
          <h2 style={{ 
            fontFamily: config.fonts.heading, 
            fontSize: '2rem', 
            marginBottom: '1rem',
          }}>Our Technology</h2>
          <p style={{ marginBottom: '1rem', lineHeight: '1.6', fontSize: '0.9rem' }}>
            Roboforge has developed a proprietary digital CNC system for composite manufacturing. Our technology combines advanced robotics, real-time sensing, and intelligent software to produce high-quality composite parts with unprecedented speed and efficiency.
          </p>
          <h3 style={{ fontFamily: config.fonts.heading, fontSize: '1.2rem', marginBottom: '0.5rem' }}>Key features:</h3>
          <ul style={{ paddingLeft: '1.5rem', marginBottom: '1rem', fontSize: '0.9rem' }}>
            <li>- Fully automated process requiring minimal labor</li>
            <li>- Continuous quality monitoring and real-time process adjustment</li>
            <li>- Flexible production capabilities for various part sizes and complexities</li>
            <li>- Compatible with a wide range of thermoplastic composite materials</li>
          </ul>
          <h3 style={{ fontFamily: config.fonts.heading, fontSize: '1.2rem', marginBottom: '0.5rem' }}>Benefits:</h3>
          <ul style={{ paddingLeft: '1.5rem', marginBottom: '1rem', fontSize: '0.9rem' }}>
            <li>- Drastically reduced lead times - from months to days</li>
            <li>- Significant cost savings compared to traditional methods</li>
            <li>- Consistent, high-quality output</li>
            <li>- Scalable production to meet varying demand</li>
          </ul>
          <p style={{ marginBottom: '1rem', lineHeight: '1.6', fontSize: '0.9rem' }}>
            Roboforge's technology enables businesses to leverage the superior properties of composites without the traditional barriers of high costs and long lead times.
          </p>
        </div>
      </div>
    </section>
  );
}