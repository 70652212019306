import React from 'react';
import { config } from '../config';

const teamMembers = [
  { name: 'Michael Benedict', image: 'MB_headshot.png', description: 'Co-founder & CEO', linkedin: 'https://linkedin.com/in/michael-benedict' },
  { name: 'Chris Paulson', image: 'CP_headshot.png', description: 'Co-founder & CTO', linkedin: 'https://linkedin.com/in/chris-paulson' },
];

export default function Team() {
  return (
    <section id="team" style={{
      maxWidth: config.sizes.maxWidth,
      margin: '0 auto',
      padding: config.spacing.section,
    }}>
      <h2 style={{ fontFamily: config.fonts.heading, fontSize: '2.5rem', marginBottom: '2rem', textAlign: 'center' }}>Our Team</h2>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', flexWrap: 'wrap' }}>
        {teamMembers.map((member, index) => (
          <div key={index} style={{ textAlign: 'center', maxWidth: '300px' }}>
            <img src={member.image} alt={member.name} style={{ width: '200px', height: '200px', borderRadius: '50%', marginBottom: '1rem' }} />
            <h3 style={{ fontFamily: config.fonts.heading, fontSize: '1.5rem', marginBottom: '0.5rem' }}>{member.name}</h3>
            <p style={{ marginBottom: '0.5rem' }}>{member.description}</p>
            <a href={member.linkedin} target="_blank" rel="noopener noreferrer" style={{ color: config.colors.primary, fontSize: '1.5rem' }}>
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}