import React from 'react';
import { config } from './config';
import Header from './components/Header';
import Hero from './components/Hero';
import Technology from './components/Technology';
import Team from './components/Team';
import Advisors from './components/Advisors';
import Footer from './components/Footer';

export default function App() {
  return (
    <div style={{ 
      backgroundColor: config.colors.background, 
      color: config.colors.primary,
      fontFamily: config.fonts.main,
    }}>
      <Header />
      <Hero />
      <Technology />
      <Team />
      <Advisors />
      <Footer />
    </div>
  );
}