import React, { useState, useEffect, useRef } from 'react';
import { config } from '../config';

export default function Hero() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);
  const imageWidth = 1024; // Full width of each image
  const images = Array.from({ length: 8 }, (_, i) => `image_${i + 1}.jpg`);
  const totalWidth = images.length * imageWidth;

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      setScrollPosition((prevPosition) => {
        const newPosition = prevPosition + 1; // Adjust this value to change scroll speed
        return newPosition >= totalWidth ? 0 : newPosition;
      });
    }, 32); // Approximately 30 FPS

    return () => clearInterval(scrollInterval);
  }, [totalWidth]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollPosition;
    }
  }, [scrollPosition]);

  return (
    <section
      id="hero"
      style={{
        height: '100vh',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div
        ref={containerRef}
        style={{
          height: '100%',
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {[...images, ...images].map((src, index) => (
          <div
            key={index}
            style={{
              display: 'inline-block',
              width: `${imageWidth}px`,
              height: '100%',
              backgroundImage: `url(${src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        ))}
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.65)', // Semi-transparent overlay
        }}
      >
        <div
          style={{
            width: '60%',
            maxWidth: config.sizes.maxWidth,
            background: 'radial-gradient(closest-side, rgba(0,0,0,.95) 0%, rgba(0,0,0,0) 100%)',
            padding: '2rem',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <h1 style={{
            fontFamily: config.fonts.heading,
            fontSize: '3rem',
            marginBottom: '1rem',
            color: config.colors.primary,
          }}>
            Carbon Fiber Composites at Unprecedented Scale and Speed
          </h1>
          <p style={{
            fontFamily: config.fonts.main,
            fontSize: '1.5rem',
            marginBottom: '2rem',
            color: config.colors.primary,
            maxWidth: '90%',
          }}>
            Digital CNC manufacturing for composites. Faster turnaround. Lower costs. No compromises.
          </p>
          <a
            href="mailto:info@roboforge.io?subject=Quote%20Request&body=I'm%20interested%20in%20getting%20a%20quote%20for%20your%20services."
            style={{
              backgroundColor: config.colors.primary,
              color: config.colors.background,
              fontFamily: config.fonts.main,
              fontSize: '1.2rem',
              padding: '0.8rem 1.5rem',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              textDecoration: 'none',
              transition: 'background-color 0.3s ease',
              marginTop: '1rem',
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = config.colors.secondary}
            onMouseOut={(e) => e.target.style.backgroundColor = config.colors.primary}
          >
            Get a Quote
          </a>
        </div>
      </div>
    </section>
  );
}