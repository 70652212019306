import React from 'react';
import { config } from '../config';

export default function Footer() {
  return (
    <footer style={{
      backgroundColor: config.colors.secondary,
      color: config.colors.primary,
      padding: '2rem',
      textAlign: 'center',
    }}>
      <div style={{
        maxWidth: config.sizes.maxWidth,
        margin: '0 auto',
      }}>
        <p>&copy; 2024 Roboforge. All rights reserved.</p>
      </div>
    </footer>
  );
}